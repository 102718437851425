.txt::before {
	content: '';
	position: absolute;
	inset: -50%;
	mask-image: radial-gradient(ellipse at center, black 25%, transparent 50%);

	@apply backdrop-brightness-75;
}

/* Create a class to wrap the MuxPlayer component */
.muxPlayerSettings mux-player {
	/* Hide all controls at once */
	--controls: none;

	/* Hide the error dialog */
	--dialog: none;

	/* Hide the loading indicator */
	--loading-indicator: none;

	/* Target all sections by excluding the section prefix */
	--play-button: none;
	--live-button: none;
	--seek-backward-button: none;
	--seek-forward-button: none;
	--mute-button: none;
	--captions-button: none;
	--airplay-button: none;
	--pip-button: none;
	--fullscreen-button: none;
	--cast-button: none;
	--playback-rate-button: none;
	--volume-range: none;
	--time-range: none;
	--time-display: none;
	--duration-display: none;
	--rendition-selectmenu: none;

	/* Target a specific section by prefixing the CSS var with (top|center|bottom) */
	--center-controls: none;
	--bottom-play-button: none;

	width: 100%;
	height: 100vh;
	--media-object-fit: cover;
}

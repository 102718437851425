.code {
	counter-reset: step;
	counter-increment: step 0;

	& :global(.line)::before {
		content: counter(step);
		counter-increment: step;
		width: 2ch;
		margin-right: 2ch;
		display: inline-block;
		text-align: right;
		font-variant: tabular-nums;
		opacity: 0.2;
	}
}

.code :global(.highlight) {
	@apply relative *:relative *:z-[1];

	&:not(:empty)::after {
		content: '';
		position: absolute;
		z-index: 0;
		inset-block: -2px;
		inset-inline: -1.6ch -0.5ch;
		@apply rounded-r border-l-4 border-green-400 bg-green-400/20;
	}
}
